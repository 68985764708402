
.input-box {
  position: relative;
  width: 100%;
  max-width: 60px;
  height: 100%;
  /* transition: all 0.5s ease-in-out; */
}

.input-box.open {
  max-width: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  z-index: 1;

}

.input-box input {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  border: none;
  border-radius: 6px;
  outline: none;
  transition: all 0.5s ease-in-out;
}

.input-box.open input {
  padding: 0 15px 0 65px;
}

.input-box .search {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  background-color: var(--color-white);
  cursor: pointer;
}

.input-box .close-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
}

.input-box.open .close-icon {
  transform: translateY(-50%) rotate(180deg);
  pointer-events: auto;
  opacity: 1;
}
