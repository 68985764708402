.search-wrapper {
  background: var(--color-white);
  position: relative;
  display: flex;
  align-items: center;
}
.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.8s ease;
  transition-delay: 0.3s;
}
.search-list-left {
  left: 30px;
}
.search-modal-left {
  left: 20px;
}

.search-wrapper-height {
  height: 70px;
}

.input-list-search {
  width: 100%;
  border: none;
  background: var(--color-white);
  color: var(--grey);
  border: none;
  padding: 10px 10px 10px 55px;
  border-radius: 5px;
  box-shadow: var(--box-shadow);

  &::placeholder {
    color: var(--grey);
  }

  &:focus {
    outline: none;
  }
}
