@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,700;0,800;0,900;1,700;1,800;1,900&display=swap");
@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);

$primary: #6b5ca4;
$secondary: #858780;
$success: #2edc4b;
$danger: #db2c59;
$warning: #f2c94c;
// $warning: #f7936f;
$light: #858780;
$dark: #2a2b2f;
$white: #fefefe;
$info: #4dc4ff;
$border-radius-sm: 5px;
$border-radius-lg: 10px;
$border-color: #858780;
$modal-backdrop-bg: rgba(49, 49, 49, 0.3);
$box-shadow-sm: 0 0px 4px rgba(0, 0, 0, 0.1);

$h1-font-size: 50px;
$h2-font-size: 35px;
$h3-font-size: 24px;
$h4-font-size: 20px;
$h5-font-size: 18px;
$h6-font-size: 16px;

@import "~bootstrap/scss/bootstrap";

:root {
  --Figtree: "Figtree";
  --primary: #6b5ca4;
  --primaryLight: rgba(107, 92, 164, 0.18);
  --textLight: #858780;
  --bg-secondary: #fafafa;
  --btn-secondary: #e4e5e9;
  --danger: #db2c59;
  --success: #2edc4b;
  --titleWhite: #fefefe;
  --warning: #f2c94c;
  --medium: #ff8900;
  --low: #f9ea68;
  --dark: #2a2b2f;
  --info: #4dc4ff;
  --montse: "Montserrat";
  --questrial: "Questrial";
  --overlay: #000000;
  --title-text: #fefefe;
  --text-black: #2a2b2f;
  --Questrial: "Questrial";
  --cabin: "Cabin", sans-serif;
  --box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
  --bs-link-hover-color: #6b5ca4 !important;
  --bs-link-color: #6b5ca4;
  --add_btn: #2edc4b;
  --add_btnlight: rgb(46 220 73 / 0.25);
  --redlight: #db2c5947;
  --pen_btn: #f2c94c;
  --chartblue: #5ebee4;
  --chartyellow: #fec140;
  --bg-page: #ffffff;
  --bg-page-darker: #f7f7f7;
  --bg-page-darkest: #b3b3b3;
  --colour-primary: #3996fb;
  --colour-primary-lightest: #e8f3ff;
  --colour-primary-darker: #1a7ee6;
  --colour-third: #419d78;
  --colour-third-lighter: #7bc9aa;
  --colour-third-lightest: #e6f7f0;
  --colour-text: #696969;
  --colour-text-lighter: #9b9b9b;
  --colour-text-darker: #626262;
  --colour-text-darkest: #363636;
  --border-color: #e8e7e7;
  --form-radius: 13px;
  --search-form-bg-colour: #f2f2f2;
  --send-form-bg-colour: #f2f2f2;
  --send-btn-box-shadow-colour: #7bbafd;
  --datePicker: #df678c;
  --datePickerpurple: #3d155f;
}

* {
  font-family: var(--Figtree);
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

/* -----------------------------LAYOUT-------------------------- */
.error-field {
  color: var(--danger) !important;
  border-bottom: 1px solid var(--danger) !important;
  padding-right: 0 !important;
  text-transform: none !important;
}
.fs-14 {
  font-size: 14px;
}

.table-responsive {
  overflow-x: inherit !important;
}

.eyeicon {
  position: absolute;
  right: 0px;
  top: 40px;
  // z-index: 2;
}
.Cursoricon {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.shadow-bg {
  box-shadow: var(--box-shadow);
}

.dateandtimeArabic {
  direction: ltr;
  text-align: right;
}

// input[placeholder],
// [placeholder],
// *[placeholder] {
//   color: var(--textLight);
// }

.select__input {
  opacity: 1 !important;
}

#ArabicDate {
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
}

.eyeicon-right {
  position: absolute;
  left: 0px;
  top: 30px;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.was-validated .form-control,
.was-validated .form-select {
  background-image: none !important;
  // background-image: url("../src/assets/sorting.png") !important;
}

.form-check-input:checked[type="checkbox"] {
  background-image: none !important;
}

input[type="checkbox"] {
  width: 25px !important;
  height: 25px !important;
}

input[type="checkbox"]::before {
  position: absolute;
  content: "";
  display: block;
  top: 18% !important;
  left: 35% !important;
  width: 7px;
  height: 12px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.select-wrapper .select__option:hover {
  background: rgba(107, 92, 164, 0.1803921569) !important;
  color: var(--primary) !important;
}

.select-wrapper .select__option:focus {
  background: rgba(107, 92, 164, 0.1803921569) !important;
  color: var(--primary) !important;
}

.select-wrapper .select__option--is-selected {
  background-color: var(--primary) !important;
}

// ----------------------------------aysnc select------------------
.select-wrapper-async .select__option:hover {
  background: rgba(107, 92, 164, 0.1803921569) !important;
  color: var(--primary) !important;
}

.select-wrapper-async .select__option:focus {
  background: rgba(107, 92, 164, 0.1803921569) !important;
  color: var(--primary) !important;
}

.select-wrapper-async .select__option--is-selected {
  background-color: var(--primary) !important;
}

.autolocation .pac-target-input {
  width: 100% !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid #bdc1c6 !important;
  border-radius: 0% !important;
  border-color: #bdc1c6 !important;
}

.autolocation-border .pac-target-input {
  width: 100% !important;
  outline: none !important;
  padding-inline-start: 15px;
  min-height: 38px;
  border: 1px solid var(--textLight);
  border-radius: 5px;
  background: transparent;
}

.combobox-input {
  width: 100% !important;
  outline: none !important;
  padding-inline-start: 15px;
  min-height: 38px;
  border: 1px solid #bdc1c6;
  border-radius: 3px;
}

.pac-item:hover {
  background: rgba(107, 92, 164, 0.1803921569) !important;
  color: var(--primary) !important;
}

.pac-matched:hover {
  color: var(--primary) !important;
}

.autolocation input[placeholder],
[placeholder],
*[placeholder] {
  padding: 6px 0px;
}

.autolocation-border input[placeholder],
[placeholder],
*[placeholder] {
  padding: 0px 10px;
}

// ---------------------------------------------------cpu ram progressbar-------------------------

.progress {
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  display: flex;
  height: 10px;
  // min-width: 100%;
}

.fornova {
  font-size: 12px;
}

.progress-value {
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: var(--success);
  height: 7px;
  // width: 0;
}

#nonOperation {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.css-12a83d4-MultiValueRemove:hover {
  background-color: rgba(107, 92, 164, 0.1803921569) !important;
  color: var(--primary) !important;
}

.css-1p3m7a8-multiValue {
  background-color: rgba(107, 92, 164, 0.1803921569) !important;
}

.css-wsp0cs-MultiValueGeneric {
  color: var(--primary) !important;
}

.css-tj5bde-Svg {
  fill: var(--primary) !important;
}

.css-3iigni-container {
  background: #e9ecef !important;
  color: var(--text-black) !important;
}

.css-16xfy0z-control {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #bdc1c6 !important;
  border-radius: 0% !important;
}

.select-wrapper .css-t3ipsp-control {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid #bdc1c6 !important;
  border-radius: 0% !important;
  border-color: #bdc1c6 !important;
}

.select-wrapper-async .css-t3ipsp-control {
  outline: none !important;
  box-shadow: none !important;
  border-color: #bdc1c6 !important;
}

#filterSelect {
  border: 1px solid #bdc1c6 !important;
  border-color: #bdc1c6 !important;
}

.dropdown-item:hover {
  background-color: rgba(107, 92, 164, 0.1803921569) !important;
  color: var(--primary) !important;
}

.dropdown-item:active {
  background-color: var(--primary) !important;
  color: var(--title-text) !important;
}

.dropdown-menu.show {
  width: 100% !important;
}

.was-validated .form-control:focus,
.was-validated .form-select:focus {
  box-shadow: none !important;
}

.was-validated .form-control .disabled-field {
  color: var(--dark) !important;
  background-color: #e9ecef !important;
}

.disabled-field
  .css-1p3m7a8-multiValue
  .select__multi-value__remove.css-12a83d4-MultiValueRemove
  .select__multi-value__remove.css-v7duua {
  display: none !important;
  background-color: #bdc1c6 !important;
}

.form-control:disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}

input:disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}

.PhoneInput--disabled {
  background-color: #e9ecef !important;
}

.rmdp-input:disabled {
  background-color: #e9ecef !important;
}

input,
input.form-control,
input.form-select {
  color: #{var(--text-black)};
  font-weight: 500;
}

.invalid-feedback {
  color: var(--danger);
}

.forinput {
  margin-inline-start: 0.7rem;
}

label {
  font-family: var(--questrial);
  color: var(--text-black) !important;
}

.logo img {
  color: var(--primary) !important;
}

.forimage {
  padding-left: 5rem !important;
}

.loginlayoutleft {
  background: var(--bg-secondary);
}

.color-black {
  color: var(--text-black);
}

.bgimage img {
  width: 100%;
  height: 100%;
  background-size: contain;
}

.bgimage {
  position: relative;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.8);
}

.in-active-member {
  color: var(--danger);
}

.high-priority {
  color: var(--danger) !important;
}

.low-priority {
  color: var(--low) !important;
}

.medium-priority {
  color: var(--medium) !important;
}

.active-member {
  color: #2edc4b;
}

.pending-member {
  color: #f2c94c;
}

.ticket-medium {
  color: var(--medium) !important;
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 36px !important;
  }
}

@media screen and (max-width: 2200px) and (min-width: 1401px) {
  .content {
    left: 12% !important;
  }
}

.content {
  position: absolute;
  margin: 0 auto;
  left: 6%;
  bottom: 0%;
}

.content_down {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.kd-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 44px;
  font-weight: 500;
  color: var(--titleWhite);
}

.description {
  padding-bottom: 6rem;
}

.description p {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
  color: var(--titleWhite);
}

.line {
  border: 2px solid var(--primary);
  border-radius: 2px;
  width: 25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.was-validated .form-control:valid {
  padding-right: 0px !important;
}

.form-control-invalid {
  color: var(--danger) !important;
  background-image: none !important;
  border-bottom-color: var(--danger) !important;
}

// ------------------------------------header---------------------------
.motion {
  -webkit-animation: motion 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: motion 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes motion {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes motion {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

time {
  font-family: var(--questrial);
  color: var(--textLight);
}

.sort .form-select {
  border-color: var(--textLight) !important;
  box-shadow: none !important;
  color: var(--textLight) !important;
  width: 150px !important;
  border-radius: 5px !important;
  background-image: url("../src/assets/sorting.png");
}

.fordesign .dropdown-toggle {
  border-color: var(--textLight) !important;
  border-radius: 5px !important;
  color: var(--dark) !important;
  padding: 3px 10px !important;
  width: 210px;
  font-family: var(--questrial);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fordashboard .dropdown-toggle {
  border-color: #bdc1c6 !important;
}

.fordashboard .dropdown-toggle {
  border-radius: 5px !important;
  color: var(--text-black) !important;
  padding: 3px 10px !important;
  font-weight: 500 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#dropdownbasic {
  width: 330px;
}

.filterbox {
  // width: 130px;
  width: max-content;
  min-height: 39px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--textLight);
  padding: 0.375rem 0.8rem 0.375rem 0.75rem;
  border-radius: 5px !important;
  margin-inline-start: 15px;
  cursor: pointer;
}
.btn-i18n {
  margin-inline-start: 15px;
}

.foractivefilter {
  border: 1px solid var(--success) !important;
}

.foractivesort {
  border: 1px solid var(--success) !important;
}

.sortbox {
  width: max-content;
  min-height: 39px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--textLight);
  padding: 0.375rem 0.8rem 0.375rem 0.75rem;
  border-radius: 5px !important;
  margin-inline-start: 20px;
  cursor: pointer;
}

.by {
  color: var(--textLight);
}

.filtersubmit {
  background-color: var(--success);
  color: var(--titleWhite);
  border-radius: 5px;
  display: inline;
  margin-inline-start: 10px;
}

.filtersubmit:hover {
  background-color: var(--success);
  color: var(--titleWhite);
  border-radius: 5px;
  display: inline;
  margin-inline-start: 10px;
}

.act_btn {
  color: var(--success);
  padding: 3px;
  border-radius: 5px;
  border: 1px solid var(--success);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
}

.pen_btn {
  color: var(--pen_btn);
  padding: 3px;
  border-radius: 5px;
  border: 1px solid var(--pen_btn);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
}

.blo_btn {
  color: var(--danger);
  padding: 3px;
  border-radius: 5px;
  border: 1px solid var(--danger);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
}

.Status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.Status h6 {
  text-align: center;
  border: 1px solid var(--textLight);
  border-radius: 5px;
  width: 100px;
  font-family: var(--questrial);
}

.Status h6:focus {
  border: 1px solid var(--text-black);
  color: var(--text-black);
}

.filter-btn-active {
  border: 1px solid var(--text-black) !important;
  color: var(--text-black) !important;
  background: rgba(107, 92, 164, 0.1803921569) !important;
  font-weight: 600;
}

.Status h6:hover {
  border: 1px solid var(--text-black);
  color: var(--text-black);
}

.fordesign .btn.show {
  color: var(--textLight) !important;
  background-color: transparent !important;
  border-color: var(--textLight) !important;
  border-radius: 5px !important;
}
.fordesign .btn {
  width: 100% !important;
  background: transparent !important;
  min-height: 38px !important;
}

.fordesign .btn:hover {
  color: var(--textLight) !important;
  background-color: transparent !important;
  border-radius: 5px !important;
  border-color: var(--textLight) !important;
}

.fordashboard .btn.show {
  color: var(--text-black) !important;
  background-color: transparent !important;
  border-color: #bdc1c6 !important;
}

.fordashboard .btn:hover {
  color: var(--text-black) !important;
  background-color: transparent !important;
  border-color: #bdc1c6 !important;
}

.sort_mobile .form-select {
  border-color: var(--textLight) !important;
  box-shadow: none !important;
  color: var(--textLight) !important;
  border-radius: 5px !important;
  border: none !important;
  width: 40px !important;
  background-color: #fafafa !important;
  background-image: url("../src/assets/sorting.png");
}

.filter_mobile .form-select {
  border-color: var(--textLight) !important;
  box-shadow: none !important;
  color: var(--textLight) !important;
  border-radius: 5px !important;
  width: 40px !important;
  border: none !important;
  background-color: #fafafa !important;
  background-image: url("../src/assets/filter.png");
}

.roleForm {
  cursor: pointer;
}

.roleForm .form-select {
  background-image: url("../src/assets/arrow.png") !important;
}

.filter .form-select {
  border-color: var(--textLight) !important;
  box-shadow: none !important;
  color: var(--textLight) !important;
  width: 150px !important;
  border-radius: 5px !important;
  background-image: url("../src/assets/filter.png");
}

.sort .form-select:focus {
  border-color: var(--textLight) !important;
}

.btn_invite_member {
  width: max-content;
  background: var(--success) !important;
  border-radius: 5px !important;
  border: 1px solid var(--success) !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  margin-inline-start: 15px;
}

.btn_download {
  width: max-content;
  background: transparent !important;
  border: 1px solid var(--textLight) !important;
  color: var(--textLight) !important;
  border-radius: 5px;
  font-weight: 400;
  line-height: 1.5;
  padding: 6px 12px;
  margin-inline-start: 15px;
}

.btn_in_member {
  width: max-content;
  background: var(--success) !important;
  border-radius: 5px !important;
  border: 1px solid var(--success) !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  margin-inline-start: 8px;
}

.btn_update {
  background: var(--success) !important;
  border-radius: 5px !important;
  border: none !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.btn_view {
  background: transparent !important;
  border-radius: 5px !important;
  border: 1px solid var(--primary) !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: var(--primary) !important;
  width: max-content;
}

.btn_mark {
  border-radius: 5px !important;
  border: none !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  width: max-content;
}

.btn_mark.active {
  background: var(--success) !important;
  color: var(--titleWhite) !important;
}

.btn_mark.in-active {
  background: transparent !important;
  border: 1px solid var(--danger) !important;
  color: var(--danger) !important;
}

.btn_cancel {
  background: none !important;
  border-radius: 5px !important;
  border: 1px solid var(--danger) !important;
  color: var(--danger) !important;
  font-weight: 300 !important;
  line-height: 1.5 !important;
}

.btn_reject {
  background: transparent !important;
  border-radius: 5px !important;
  border: 1px solid var(--danger) !important;
  color: var(--danger) !important;
  font-weight: 300 !important;
  line-height: 1.5 !important;
}

#doc_searchQueryInput {
  box-shadow: var(--box-shadow);
  background: #fefefe;
  border-radius: 5px;
  height: 2.8rem;
  outline: none;
  border: none;
  padding-inline-start: 0.7rem;
  margin-inline-start: 5px;
  font-size: 1rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #doc_searchQueryInput {
    margin-inline-start: 0px;
  }
}

#doc_searchBOMInput {
  box-shadow: var(--box-shadow);
  background: #fefefe;
  border-radius: 5px;
  height: 2.8rem;
  outline: none;
  border: none;
  padding-inline-start: 0.7rem;
  font-size: 1rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #doc_searchBOMInput {
    margin-inline-start: 0px;
  }
}

#doc_searchQueryInputfilter {
  box-shadow: var(--box-shadow);
  background: #fefefe;
  border-radius: 5px;
  height: 1.8rem;
  outline: none;
  border: 1px solid var(--textLight);
  // border-radius: 1.625rem;
  // padding: 0 3.5rem 0 3.5rem;
  padding-inline-start: 0.7rem;
  font-size: 1rem;
  width: 100%;
}

.input_width {
  width: 100%;
}

.list_members {
  background: #fafafa;
  min-height: 90vh;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
  height: 95px !important;
  font-weight: 500 !important;
}

.members h5 {
  font-weight: 600;
  display: inline-block;
  padding-bottom: 5px;
  color: var(--text-black);
  border-bottom: 2px solid var(--text-black);
  text-transform: capitalize !important;
  width: max-content;
  font-size: 16px;
}

.membertitle {
  cursor: pointer;
}

.date_center {
  cursor: pointer;
  background: var(--primary);
  color: white;
  border-radius: 37px;
  font-size: 12px;
}

.profilename {
  border-bottom: none !important;
}

.inmember {
  font-weight: 600;
  display: inline-block;
  padding-bottom: 5px;
  color: var(--text-black);
  border-bottom: 4px solid var(--text-black);
}

.memberimageshape {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.memberimageshape img {
  object-fit: cover;
  border-radius: 50%;
}

.sb-avatar__text {
  // background-color: var(--bg-secondary) !important;
  // color: var(--primary) !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
}
.sb-avatar__text div {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.sb-avatar {
  border-radius: 50% !important;
}

.memberimageshapelayout {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.memberimageshapelayout img {
  border-radius: 50%;
  object-fit: cover;
}

#doc_searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#doc_searchQuerySubmit:hover {
  cursor: pointer;
}

.navbar__mobile_menu_links {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--primary);
  display: grid;
  top: 0px;
  left: 0;
  transition: 0.3s ease-in-out;
}

.navbar__mobile_menu_links_drop {
  color: var(--titleWhite);
}

.header {
  background: var(--primary);
  position: sticky;
  top: 0px;
  z-index: 111;
}

.hero {
  width: 300px;
}

.emailprofile {
  color: var(--textLight);
}

::-webkit-scrollbar {
  width: 1px;
  height: 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(204, 25, 25, 0);
}

::-webkit-scrollbar-thumb {
  background: var(--primaryLight);
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(248, 241, 241, 0);
}

.desktop_view {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.mobile_view {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 34vh;
}

.MobileMenu {
  z-index: -999;
}

.editmemberlinks {
  min-width: 160px;
}

.editmemberlinks {
  overflow-y: scroll;
}

.edit_link_routes {
  text-decoration: none;
  color: var(--primary);
  position: relative;
  width: max-content;
}

.edit_link_routes:hover {
  color: var(--primary);
  font-weight: 600;
  cursor: pointer;
}

.edit_link_routes.active {
  color: var(--primary) !important;
  font-weight: 600 !important;
}

.edit_link_routes.active:after {
  width: 100%;
  left: 0;
}

.edit_link_routes:after {
  bottom: -15px;
  margin-bottom: 10px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: var(--primary);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.edit_link_routes:focus:after {
  width: 100%;
  left: 0;
}

.edit_link_routes:hover:after {
  width: 100%;
  left: 0;
}

.doc_link_routes {
  text-decoration: none;
  color: var(--titleWhite);
  position: relative;
  font-family: var(--questrial);
}

.doc_link_routes:hover {
  color: var(--titleWhite);
  font-weight: 600;
}

.doc_link_routes.active {
  color: var(--titleWhite);
  font-weight: 600 !important;
}

.doc_link_routes.active:after {
  width: 100%;
  left: 0;
}

.doc_link_routes:after {
  bottom: -15px;
  margin-bottom: 10px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: var(--titleWhite);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.doc_link_routes:focus:after {
  width: 100%;
  left: 0;
}

.doc_link_routes:hover:after {
  width: 100%;
  left: 0;
}

.profile_tirgger img {
  cursor: pointer;
}

.dropdown_menu {
  position: absolute;
  top: 65px;
  right: -5px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 250px;
  box-shadow: var(--box-shadow);
}

.dropdown_menuArabic {
  position: absolute;
  top: 65px;
  left: -5px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 250px;
  box-shadow: var(--box-shadow);
}

.angle {
  content: "";
  position: absolute;
  top: 100px;
  right: 40px;
  height: 0px;
  width: 20px;
  background: #fefefe;
  box-shadow: var(--box-shadow);
  transform: rotate(45deg);
}

ul {
  padding: 0 !important;
  list-style-type: none;
}

.dropdown_menu ul li {
  padding: 10px 0;
  border-top: 1px solid #dee2e6;
}

.dropdown_menu ul li a {
  color: var(--primary);
  font-weight: 500;
}

.dropdown_menu ul li a:hover {
  color: var(--primary);
  cursor: pointer;
}

.ulforMobile li a {
  color: var(--titleWhite);
  font-weight: 500;
}

.ulforMobile {
  border-top: 1px solid #bdc1c63d;
}

.mobileBorder {
  border-top: 1px solid #bdc1c63d;
}

.langpadding {
  padding-inline-start: 0.7rem;
}

.dropdown_menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: 500ms ease;
}

.dropdown_menu.inactive {
  opacity: 1;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 500ms ease;
}

.dropdown_menuArabic ul li {
  padding: 10px 0;
  border-top: 1px solid #dee2e6;
}

.dropdown_menuArabic ul li a {
  color: var(--primary);
  font-weight: 500;
}

.dropdown_menuArabic ul li a:hover {
  color: var(--primary);
  cursor: pointer;
}

.dropdown_menuArabic.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: 500ms ease;
}

.dropdown_menuArabic.inactive {
  opacity: 1;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 500ms ease;
}

.viewprofiledropdown {
  padding-inline-start: 0.7rem;
}

.dropdownlinks {
  padding-inline-start: 0.7rem;
}

.dropdownlinks.active {
  font-weight: 600;
}

.dropdownfilterRole {
  position: absolute;
  top: 45px;
  right: 0px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.dropdownfilter {
  position: absolute;
  top: 45px;
  right: -3px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 260px;
  height: 70vh;
  overflow-y: scroll;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.dropdownfilter.active {
  opacity: 1;
  display: block;
  // visibility: visible;
  z-index: 1;
  cursor: pointer;
}

.dropdownfilter.inactive {
  opacity: 1;
  display: none;
  // visibility: hidden;
  cursor: pointer;
}

.dropdownfilterRole.active {
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.dropdownfilterRole.inactive {
  opacity: 1;
  visibility: hidden;
  cursor: pointer;
}

.dropdownsort {
  position: absolute;
  top: 45px;
  left: 25px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 170px;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.dropdownsort.active {
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.dropdownsort.inactive {
  opacity: 1;
  visibility: hidden;
  cursor: pointer;
}

.dropdownMark {
  position: absolute;
  top: 45px;
  right: 0px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 300px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);
  z-index: 1;
  cursor: pointer;
}

.dropdownMarkArabic {
  position: absolute;
  top: 45px;
  left: -5px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 300px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.4);
  z-index: 1;
  cursor: pointer;
}

.dropdownMark.active {
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.dropdownMarkArabic.active {
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.dropdownMark.inactive {
  opacity: 1;
  visibility: hidden;
  cursor: pointer;
}

.dropdownMarkArabic.inactive {
  opacity: 1;
  visibility: hidden;
  cursor: pointer;
}

.dropdownMark h6 {
  padding-bottom: 0.7rem;
  border-bottom: 1px solid #bdc1c6 !important;
}

.dropdownMarkArabic h6 {
  padding-bottom: 0.7rem;
  border-bottom: 1px solid #bdc1c6 !important;
}

.dropdownsortMobile {
  position: fixed;
  bottom: 60px;
  left: 0;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.dropdownsortMobile.active {
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.dropdownsortMobile.inactive {
  opacity: 1;
  visibility: hidden;
  cursor: pointer;
}

.dropdownfilterMobile {
  position: absolute;
  bottom: 0px;
  left: 0;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  height: 88vh;
  overflow-y: scroll;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.dropdownfilterMobile.active {
  opacity: 1;
  display: block;
  cursor: pointer;
}

.dropdownfilterMobile.inactive {
  opacity: 1;
  display: none;
  cursor: pointer;
}

.filter-z {
  z-index: 1;
}

.sort-z {
  z-index: 1;
}

.sortingline {
  border-bottom: 1px solid var(--textLight);
  padding-bottom: 5px;
}

.filterline {
  border-bottom: 1px solid var(--textLight);
  padding-bottom: 5px;
}

.sortingh6 h6 {
  color: var(--textLight);
  font-family: var(--questrial);
}

/* -----------------------------LOGIN-------------------------- */

@media screen and (max-width: 1200px) {
  .loginscreen {
    padding: 1rem 5rem !important;
  }

  .forgotpassword {
    padding: 1rem 5rem !important;
  }

  .createnewpassword {
    padding: 1rem 5rem !important;
  }
}

.head_title {
  color: var(--text-black);
}

.head_content {
  color: var(--titleWhite) !important;
}

.head_description {
  font-weight: 400;
  color: var(--text-black);
  font-family: var(--questrial);
}

.ff-questrial {
  font-family: var(--questrial);
}

.loginscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem;
}

.forgotpassword {
  padding: 5rem;
}

.createnewpassword {
  padding: 5rem;
}

.formprofile .form-control {
  border: 0 !important;
  border-bottom: 1px solid #bdc1c6 !important;
  border-radius: 0px;
  box-shadow: none;
  background-color: transparent;
  padding: 0.375rem 0rem !important;
}

.formprofile > label {
  padding: 0rem 0rem !important;
  color: var(--textLight) !important;
  font-family: var(--Questrial);
  font-weight: 400;
}

.floating .form-floating > .form-control,
.form-floating > .form-control {
  border: 0 !important;
  border-bottom: 1px solid #bdc1c6 !important;
  border-radius: 0px;
  box-shadow: none;
  background-color: none;
}

.was-validated .form-control,
.was-validated .form-select {
  padding-left: 0;
  padding-right: 0;
  border: 0 !important;
  border-bottom: 1px solid #bdc1c6 !important;
  // background-color: transparent !important;
  border-radius: 0;
}

.disabled-field {
  color: var(--dark) !important;
  background-color: #e9ecef !important;
}

.was-validated .form-control:invalid,
.was-validated .form-select:invalid {
  color: var(--danger);
  border-bottom: 1px solid var(--danger) !important;
  padding-right: 0 !important;
}

.floating .form-floating > label {
  padding: 0rem 0rem !important;
  color: var(--textLight) !important;
  font-family: var(--Questrial);
  font-weight: 400;
}

.floating {
  padding-top: 2rem;
}

.forgot {
  font-size: 16px !important;
  color: var(--primary) !important;
}

.remember {
  font-size: 14px;
  margin-left: 10px;
  font-family: var(--Questrial);
}

input[type="email"] {
  text-transform: none !important;
}

input[type="checkbox"] {
  position: relative;
  width: 20px;
  height: 20px;
  color: #363839;
  border: 1px solid #bdc1c6;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

input[type="checkbox"]::before {
  position: absolute;
  content: "";
  display: block;
  top: 1px;
  left: 5px;
  width: 7px;
  height: 12px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

input[type="checkbox"]:checked {
  color: #fff;
  border-color: var(--primary);
  background: var(--primary);
}

input[type="checkbox"]:checked::before {
  opacity: 1;
}

.dont {
  font-weight: 600;
  color: var(--text-black);
}

/* -----------------------------LOGIN-------------------------- */

// -----------------------------------------------------------invitation---------------------------------------

.invitation {
  height: 55vh;
  overflow-y: scroll;
}

// ---------------------------------------------list members-------------------------

.table_class {
  position: relative;
}

.table_class h6 {
  color: var(--textLight);
  font-family: var(--questrial);
}

.table_class td {
  color: #202020 !important;
  font-weight: 600;
}

.table_white {
  background: #fefefe !important;
  border-radius: 5px !important;
}
.table_white_head {
  background: var(--primaryLight) !important;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  border-bottom-width: 0 !important;
  border-left: 0 !important;
  background-color: transparent !important;
}

.table {
  border-collapse: separate;
  border-spacing: 0 1.5rem;
  overflow: scroll;
  border-radius: 15px !important;
}

th,
td {
  vertical-align: middle !important;
}

.td_width {
  position: absolute !important;
  left: 255px;
  top: 25px;
}

.img_circle_active {
  background: var(--success);
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  border-radius: 50px;
}

.img_circle_activeArabic {
  background: var(--success);
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  left: 2px;
  border-radius: 50px;
}

.img_circle_inactive {
  background: var(--danger);
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  border-radius: 50px;
}

.img_circle_inactiveArabic {
  background: var(--danger);
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  left: 2px;
  border-radius: 50px;
}

.img_circle_pendingArabic {
  background: #f2c94c;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  left: 2px;
  border-radius: 50px;
}

.img_circle_pending {
  background: #f2c94c;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  border-radius: 50px;
}

.img_circle_low {
  background: var(--low);
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  border-radius: 50px;
}

.img_circle_high {
  background: #ff2d55;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  border-radius: 50px;
}

.img_circle_medium {
  background: var(--medium);
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  border-radius: 50px;
}

.sortfilterMobile {
  width: 100%;
  background-color: #fefefe;
  box-shadow: var(--box-shadow);
  padding: 20px;
  position: fixed;
  bottom: 0px;
}

.sortfilterMobile div h5 {
  color: var(--textLight);
}

// --------------------------------------------------------modal-------------------------------------------

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(49, 49, 49, 0.3);
  z-index: 111111;
}

.loading-text span {
  display: inline-block;
  margin: 0 5px;
  color: var(--primary);
  font-family: "Quattrocento Sans", sans-serif;
}

.loading-text span:nth-child(1) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0s infinite linear alternate;
  animation: blur-text 1.5s 0s infinite linear alternate;
}

.loading-text span:nth-child(2) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.2s infinite linear alternate;
  animation: blur-text 1.5s 0.2s infinite linear alternate;
}

.loading-text span:nth-child(3) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.4s infinite linear alternate;
  animation: blur-text 1.5s 0.4s infinite linear alternate;
}

.loading-text span:nth-child(4) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.6s infinite linear alternate;
  animation: blur-text 1.5s 0.6s infinite linear alternate;
}

.loading-text span:nth-child(5) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 0.8s infinite linear alternate;
  animation: blur-text 1.5s 0.8s infinite linear alternate;
}

.loading-text span:nth-child(6) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 1s infinite linear alternate;
  animation: blur-text 1.5s 1s infinite linear alternate;
}

.loading-text span:nth-child(7) {
  filter: blur(0px);
  -webkit-animation: blur-text 1.5s 1.2s infinite linear alternate;
  animation: blur-text 1.5s 1.2s infinite linear alternate;
}

@-webkit-keyframes blur-text {
  0% {
    filter: blur(0px);
  }

  100% {
    filter: blur(4px);
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }

  100% {
    filter: blur(4px);
  }
}

.popup_inner {
  position: relative;
  width: 100%;
  max-width: 640px;
  background-color: var(--titleWhite);
  border-radius: 5px;
}

.toastinner {
  position: relative;
  width: 100%;
  max-width: 400px;
  background-color: var(--titleWhite);
  border-radius: 15px;
}

.ToastQr {
  position: relative;
  max-width: 200px;
  width: 100%;
  background-color: var(--titleWhite);
}

.toastsecond {
  position: relative;
  background-color: var(--titleWhite);
  border-radius: 15px;
}

.popup_inner .close_btn {
  position: absolute;
  top: 50px;
  right: 32px;
}

.logbtn {
  padding: 15px 20px;
  background: var(--primary);
  color: var(--titleWhite);
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
}

.nobtn {
  padding: 15px 20px;
  border: 1px solid var(--danger);
  color: var(--danger);
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.yesbtn {
  padding: 15px 20px;
  background: var(--success);
  border: 1px solid var(--success);
  color: var(--titleWhite);
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.logcenter {
  position: absolute;
  left: 130px;
}

// ----------------------------------------pagination------------------------------------------

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paginate-ctn {
  display: flex;
}

.round-effect {
  color: var(--primary);
  cursor: pointer;
  border: 1px solid var(--primary);
  font-size: 16px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  border-radius: 15px;
  height: 40px;
  width: 40px;
  margin: 5px;
  text-decoration: none;
}

.round-effect:hover {
  text-decoration: none;
  background: var(--primary);
  color: var(--titleWhite);
}

.round-effect:hover a {
  text-decoration: none;
  color: var(--titleWhite);
}

.activeEffect {
  background: var(--primary);
  color: var(--titleWhite);
}

.dropdown_menudot {
  position: absolute;
  top: 100px;
  right: 15px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  box-shadow: var(--box-shadow);
}

.angle {
  content: "";
  position: absolute;
  top: 100px;
  right: 40px;
  height: 0px;
  width: 20px;
  background: #fefefe;
  box-shadow: var(--box-shadow);
  transform: rotate(45deg);
}

ul {
  padding: 0;
  list-style-type: none;
}

.dropdown_menudot ul li {
  padding-top: 5px;
}

.dropdown_menudot ul li a {
  color: var(--text-black);
  font-weight: 500;
}

.dropdown_menudot ul li a:hover {
  color: var(--text-black);
  font-weight: bold;
  cursor: pointer;
}

.dropdown_menudot.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: 500ms ease;
}

.dropdown_menudot.inactive {
  opacity: 1;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 500ms ease;
}

// ------------------------------------------------------------------------------bill boards------------------------------------------------------
.billboard {
  background: #fafafa;
  min-height: 90vh;
}
.billboard-map {
  height: 80vh;
  background: #fafafa;
}

.logobillboard {
  width: 100px;
}

.logobillboard img {
  width: 100%;
}

.logobillboardmobile {
  width: 50px;
}

.logobillboardmobile img {
  width: 100%;
}

.textlocation {
  color: var(--primary) !important;
}

.billtypes {
  color: var(--text-black) !important;
  font-weight: 600;
}

.billtypesmobile {
  color: var(--text-black) !important;
  font-weight: 600;
  font-size: 12px;
  width: 315px;
}

.billformobile {
  font-size: 0.8rem;
}

.whitebox {
  background: var(--titleWhite);
}

.blackbox {
  background: var(--text-black);
  min-height: 368px;
}

.connect {
  border: 1px solid var(--titleWhite);
  color: var(--titleWhite);
  display: inline-block;
}

.connect:hover {
  border: 1px solid var(--titleWhite);
  color: var(--titleWhite);
  display: inline-block;
}

.inactive_btn {
  background: var(--danger);
  color: var(--titleWhite);
  padding: 7px;
  border-radius: 5px;
}

.Active {
  background: var(--success);
  color: var(--titleWhite);
  padding: 7px;
  border-radius: 5px;
}

.billboard_ticket {
  background-color: rgb(46 220 73 / 0.25);
  color: var(--success) !important;
  padding: 3px !important;
  border-radius: 5px !important;
  font-family: var(--questrial);
  width: max-content;
}

.billboard_ticket:hover {
  background-color: rgb(46 220 73 / 0.25);
  color: var(--success) !important;
  padding: 3px !important;
  border-radius: 5px !important;
  font-family: var(--questrial);
  width: max-content;
}

.billnumber {
  color: var(--text-black) !important;
}

.arrow_round {
  width: 30px;
  height: 30px;
  background-color: rgb(46 220 73 / 0.25);
  border-radius: 50%;
  position: relative;
}

.arrow_round img {
  position: absolute;
  left: 27%;
  top: 25%;
}

.arrow_round_red {
  width: 30px;
  height: 30px;
  background-color: #db2c5947;
  border-radius: 50%;
  position: relative;
}

.arrow_round_red img {
  position: absolute;
  left: 27%;
  top: 25%;
}

.zoomin {
  width: 40px;
  height: 40px;
  background-color: var(--bg-secondary);
  border-radius: 50%;
  position: relative;
}

.zoomin img {
  position: absolute;
  left: 22%;
  top: 23%;
}

.zoomout {
  width: 40px;
  height: 40px;
  background-color: var(--bg-secondary);
  border-radius: 50%;
  position: relative;
}

.zoomout img {
  position: absolute;
  left: 22%;
  top: 23%;
}

.teambox {
  padding-inline-start: 0.7rem;
}

.bill_tittle {
  color: var(--textLight) !important;
}

.teambox span {
  color: var(--textLight);
  font-weight: 300;
}

.teambox h6 {
  color: var(--text-black);
}

.foradd {
  padding-inline-start: 0.7rem;
}

.board_details {
  position: absolute !important;
  right: 0;
  color: var(--textLight);
}

.tableposition {
  position: relative !important;
}

.nonmarkcolor {
  background-color: #db2c591f !important;
}

.nonmarkPendingcolor {
  background-color: #f2c94c42 !important;
}

.screensizeborder {
  border-left: 1px solid var(--text-black);
  border-right: 1px solid var(--text-black);
}

.max_width {
  min-width: 110px;
}

.max_width_tickets {
  min-width: 110px;
}

.attachment {
  width: 90px;
  height: 90px;
  cursor: pointer;
}

.feed {
  // height: 210px;
  display: inline-block;
  width: 170px;
  cursor: pointer;
}

.feedbox {
  height: 210px;
  display: flex;
  justify-content: center;
}

.feed .embet {
  width: 100%;
  // height: 100%;
  border-radius: 5px;
  display: inline;
  background-repeat: no-repeat;
  background-size: contain;
  // opacity: 0.6;
}

.embetimg {
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: contain;
}

.popup-img {
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.x_close {
  right: -5px;
  top: -9px;
  cursor: pointer;
  z-index: 1;
}

.attachment .embet {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: inline;
  background-repeat: no-repeat;
  background-size: cover;
}

.view_tickets {
  background-color: var(--bg-secondary);
  border-radius: 5px;
  overflow: scroll;
}

.more {
  color: var(--primary);
  border: none !important;
}

.more:hover {
  color: var(--primary) !important;
  border: none !important;
}

.ticket_no {
  border-bottom: 2px solid var(--text-black);
}

.status {
  background-color: var(--add_btnlight);
  color: var(--success);
  border-radius: 5px;
  padding: 5px 20px;
}

.inActivestatus {
  background-color: rgba(219, 44, 89, 0.1215686275);
  color: var(--datePicker);
  border-radius: 5px;
  padding: 5px 20px;
}

.opactiyOnhover {
  opacity: 0.5;
}

.opactiyOnhover:hover {
  opacity: 1;
}

.opactiyOnhover-active {
  opacity: 1;
}

.fileupload {
  overflow: hidden;
  cursor: pointer;
}

.fileupload input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

// -------------------------------------------------------------------progress bar-------------------------------
.step-item {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 36%;
}

.step-item2 {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 36%;
}

.stepArabic {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 36%;
}

.step-item:not(:first-child):before {
  content: "";
  background-color: rgb(226 232 240);
  width: 100%;
  position: absolute;
  height: 3px;
  right: 50%;
  top: 21.333333%;
  transform: translateY(50%);
}

.stepArabic:not(:first-child):before {
  content: "";
  background-color: rgb(226 232 240);
  width: 100%;
  position: absolute;
  height: 3px;
  left: 50%;
  top: 21.333333%;
  transform: translateY(50%);
}

.step-item2:not(:first-child):before {
  content: "";
  background-color: rgb(226 232 240);
  width: 100%;
  position: absolute;
  height: 3px;
  right: 50%;
  top: 33.333333%;
  transform: translateY(50%);
}

.step {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  border-radius: 50%;
  font-weight: 600;
  background-color: rgb(226 232 240);
  color: var(--titleWhite);
}

.step2 {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  z-index: 1;
  position: relative;
  border-radius: 50%;
  font-weight: 600;
  background-color: rgb(226 232 240);
  color: var(--titleWhite);
}

.progressactive .step2 {
  background-color: var(--success);
}

.complete .step2 {
  background-color: var(--success);
}

.progressactive .step {
  background-color: var(--success);
}

.complete .step {
  background-color: var(--success);
}

.complete p {
  color: var(--titleWhite);
}

.complete:not(:first-child):before,
.progressactive:not(:first-child):before {
  background-color: var(--success);
}

// --------------------------------------------------------------------------audio reacorded-----------------

.minute {
  color: var(--titleWhite);
}

.timeload h1 {
  color: var(--text-black);
}

.pausebtn {
  background: var(--success);
  color: var(--titleWhite);
  padding: 0.5rem 2rem;
  transition: all 300ms ease-in-out;
  transform: translateY(0);
}

.pausebtn:hover {
  background: var(--success);
  color: var(--titleWhite);
  padding: 0.5rem 2rem;
  transition: all 300ms ease-in-out;
  transform: translateY(0);
}

.stopbtn {
  border: 1px solid var(--danger);
  color: var(--danger);
  padding: 0.5rem 2rem;
  transition: all 300ms ease-in-out;
  transform: translateY(0);
}

.Uploadbtn {
  width: max-content;
  background: var(--success) !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 0.5rem 2rem;
  color: var(--titleWhite);
  cursor: pointer;
  font-weight: 600 !important;
}

.stopbtn:hover {
  border: 1px solid var(--danger);
  color: var(--danger);
  padding: 0.5rem 2rem;
  transition: all 300ms ease-in-out;
  transform: translateY(0);
}

.sound {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 10px;
}

.sound-wave {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  padding: 0px;
  border-radius: 10px;
}

.soundArabic {
  direction: ltr;
  text-align: right;
}

.sound button {
  width: 40px;
  height: 80px;
  border: none;
  padding: 0;
  background-color: transparent;
}

.wave {
  display: block !important;
}

.attachline {
  border-bottom: 1.5px solid var(--text-black);
}

.billtime {
  color: var(--text-black) !important;
}

// --------------------------------------------------------------------------activity feeed-----------------------------------

.activityfeed {
  border-left: 1px solid #bdc1c6 !important;
}

.activityfeedarabic {
  border-right: 1px solid #bdc1c6 !important;
}

.actfeedheight {
  overflow: scroll;
}

.sidebox {
  width: 20px;
  height: 20px;
  background: var(--textLight);
  border-radius: 5px;
  left: -11px;
}

.sideboxarabic {
  width: 20px;
  height: 20px;
  background: var(--textLight);
  border-radius: 5px;
  right: -11px;
}

.datefeed {
  color: var(--textLight);
}

.ticketreminder {
  color: var(--danger);
}

.feedpending {
  color: var(--pen_btn);
}

.feeddone {
  color: var(--success);
}

.nestedul {
  max-width: 600px;
}

.nestedulInventory {
  max-width: 900px;
}

.errorArabic {
  direction: ltr;
}

.pointer {
  cursor: pointer !important;
}

// -----------------------------------------------------------------------------404-------------------------------------------

.File404 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-color: #fafafa;
  overflow: hidden;
}

.font404 {
  font-size: 252px;
  font-weight: 900;
  margin: 0;
  color: var(--text-black);
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -55px;
}

.font404eorr {
  font-size: 252px;
  font-weight: 900;
  margin: 0;
  color: var(--text-black);
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -55px;
  direction: ltr !important;
}

.font404eorr span {
  font-family: var(--montse) !important;
  color: var(--primary);
  text-shadow: -8px 0 0 #fff;
}

.font404 span {
  font-family: var(--montse) !important;
  color: var(--primary);
  text-shadow: -8px 0 0 #fff;
}

.oops {
  color: var(--primary);
}

.sorry {
  color: var(--primary);
}

// --------------------------------------------------------------------multi select-----------------

.select-wrapper .css-t3ipsp-control:hover {
  border: none !important;
  border-color: transparent !important;
  border-bottom: 1px solid #bdc1c6 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  outline: transparent !important;
}

.select-wrapper .css-13cymwt-control:hover {
  border: none !important;
  border-color: #bdc1c6 !important;
  border-bottom: 1px solid #bdc1c6 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  outline: transparent !important;
}

.select-wrapper .css-13cymwt-control {
  border: none !important;
  border-radius: 0 !important;
  outline: transparent !important;
  border-color: transparent !important;
  border-bottom: 1px solid #bdc1c6 !important;
  padding: 0% !important;
  background: transparent !important;
}

// --------------------------------aysnc selct--------------------------
.select-wrapper-async .css-t3ipsp-control:hover {
  outline: none !important;
  box-shadow: none !important;
  border-color: #bdc1c6 !important;
  background: transparent !important;
}

.select-wrapper-async .css-13cymwt-control {
  outline: none !important;
  box-shadow: none !important;
  border-color: #bdc1c6 !important;
  background: transparent !important;
}

.select-wrapper-async .css-13cymwt-control:hover {
  outline: none !important;
  box-shadow: none !important;
  border-color: #bdc1c6 !important;
  background: transparent !important;
}

.css-3w2yfm-ValueContainer {
  padding: 0% !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0% !important;
}

.select__value-container {
  padding: 0% !important;
}

.select__single-value {
  margin: 0% !important;
  padding-inline-start: 2px !important;
}

.PhoneInputInput {
  border: none;
  border-bottom: 1px solid #bdc1c6;
  padding: 0px !important;
}

.PhoneInputInput:focus-visible {
  border: none !important;
  // border-bottom: 1px solid #bdc1c6 !important;
  outline: none !important;
}

.select .option {
  color: var(--primary) !important;
}

// ------------------------------------------------------------profileupload-----------------------------------------

#imageUpload {
  display: none;

  + label {
    position: absolute;
    left: 35px;
    bottom: 0px;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #f1f1f1;
    }
  }
}

// ------------------------------------------------------dashboard-----------------------------------

.overviewCard {
  border-radius: 10px;
  background-color: var(--titleWhite);
  box-shadow: var(--box-shadow);
}
.TicketCard {
  background-color: var(--titleWhite);
  box-shadow: var(--box-shadow);
}

.eyebox {
  padding: 15px 15px;
  margin-top: 20px;
  border-radius: 25%;
  background-color: var(--primary);
}

.forchartIcon {
  padding: 15px 15px;
  border-radius: 25%;
  background-color: var(--titleWhite);
}

.total {
  color: var(--textLight);
}

.activeNumber {
  color: var(--success);
}

.inactiveNumber {
  color: var(--danger);
}

.chartCard {
  background-color: var(--titleWhite);
}

.total {
  color: var(--textLight);
}

.activeNumber {
  color: var(--success);
}

.inactiveNumber {
  color: var(--danger);
}

.chartCard {
  background-color: var(--titleWhite);
}

.onlineChartbox {
  background-color: var(--success);
  border-radius: 10px;
  padding: 15px 30px;
}

.offlineChartbox {
  background-color: var(--danger);
  border-radius: 10px;
  padding: 15px 30px;
}

.onlinetag {
  color: var(--titleWhite);
  font-size: 14px;
}

.billcount {
  color: var(--titleWhite);
}

.dashbox {
  width: 150px;
}

.dount {
  width: 340px;
}

.bar {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.fordashboard .dropdown-toggle::after {
  border: none !important;
}
.Actions_title .dropdown-toggle::after {
  border: none !important;
}

.linedivider {
  border-bottom: #bdc1c65c 1px solid;
}

.billcount {
  color: var(--titleWhite);
}

.EmojiPickerReact .Flex {
  display: none !important;
}

.rmdp-input {
  padding: 15px 20px !important;
  width: 240px;
  height: 40px !important;
}

.rmdp-container {
  width: 100% !important;
}

.dateRangeformobile1 {
  width: 100% !important;
}

.dateRangeformobile {
  width: 100% !important;
}

.dateRangeformobile1 .dateRangeformobile .rmdp-input {
  width: 100% !important;
}

.rmdp-week-day {
  color: var(--primary) !important;
}

.rmdp-arrow {
  border: solid var(--primary) !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid var(--primary) !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-range {
  background-color: var(--primary) !important;
  box-shadow: var(--box-shadow) !important;
  color: var(--titleWhite) !important;
}

.rmdp-day.rmdp-today span {
  background-color: var(--datePicker) !important;
  color: var(--titleWhite) !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: var(--datePicker) !important;
  color: var(--titleWhite) !important;
}

.rmdp-header-values {
  color: var(--primary) !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--primary) !important;
}

.formprofile .rmdp-input {
  border: 0 !important;
  border-bottom: 1px solid #bdc1c6 !important;
  border-radius: 0px;
  background-color: transparent;
  padding: 0.375rem 0rem !important;
  width: 100%;
  height: 37px;
  box-shadow: none;
  color: #000;
}

.formprofile .rmdp-input:focus {
  border: none !important;
  border-bottom: 1px solid #bdc1c6 !important;
  outline: none !important;
  box-shadow: none;
}
.rmdp-calendar {
  height: max-content;
  padding: 4px;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.replywd .react-video-controls {
  display: none;
}

.img-div {
  top: 40px;
  left: 40px;
}
// --------------------------------------------------------------------loder---------------------------------------------------------------

.loader-img {
  width: 35px;
  position: relative;
}

.loader {
  -webkit-animation: spin 2s linear infinite;
  border-bottom: 16px solid rgba(107, 92, 164, 0.18);
  border-color: var(--primary) rgba(107, 92, 164, 0.18) !important;
  border-radius: 50%;
  border-top: 16px solid rgba(107, 92, 164, 0.18);
  border-style: solid;
  height: 120px;
  width: 120px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  // text-shadow: 1px 1px 2px var(--primary);
  color: var(--primary);
  font-size: 15px;
}

// --------------------------------------------------------------------loder---------------------------------------------------------------

.notification-card {
  border: 1px solid var(--border-color);
  padding: 10px;
  cursor: pointer;
}

.disabled-field .rmdp-input {
  display: none !important;
  background-color: #bdc1c6 !important;
}

.notification-count {
  color: white;
}

.notification-unread {
  background: rgba(107, 92, 164, 0.1803921569);
}

.web {
  color: var(--primary);
}

.notification-unread_circle {
  background: var(--bs-purple);
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 37px;
  left: 5px;
  border-radius: 50px;
}

.inputfilevideo {
  position: absolute;
  font-size: 10px;
  opacity: 0;
}

.copy-btn {
  background: transparent;
  border: transparent;
}

#dropdown-basicActions {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
  color: var(--color) !important;
}

.Actions .dropdown-toggle::after {
  border: 0 !important;
}

@media screen and (max-width: 768px) {
  .gapAtMobileView th {
    min-width: 150px !important;
  }
}

.viewmap {
  text-decoration: underline;
  color: blue;
}

.overviewInside {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(202px, 1fr));
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}

.ExcelPdf {
  border: 1px solid var(--textLight);
  color: var(--textLight);
  padding: 5px;
  border-radius: 5px;
  font-weight: 400;
}

.mobile-search-btn {
  position: absolute !important;
  right: 0px;
  top: 50px;
  cursor: pointer;
  background-color: transparent !important;
  color: var(--primary) !important;
  border: none !important;
  box-shadow: transparent !important;
  font-family: 600 !important;
  width: max-content !important;
}
.mobile-search-btn-right {
  position: absolute !important;
  left: 0px;
  top: 50px;
  cursor: pointer;
  background-color: transparent !important;
  color: var(--primary) !important;
  border: none !important;
  box-shadow: transparent !important;
  font-family: 600 !important;
  width: max-content !important;
}

.custom_select_input__control {
  height: 65px !important;
  border: none !important;
  border-radius: 0 !important;
  outline: transparent !important;
  border-color: transparent !important;
  border-bottom: 1px solid #bdc1c6 !important;
  padding: 0% !important;
  background: transparent !important;
  box-shadow: none !important;
}

.css-t3ipsp-control {
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--textLight) !important;
}

.css-t3ipsp-control:hover {
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--textLight) !important;
}

.custom_select_input__indicator-separator {
  display: none !important;
}

.custom_select_input__multi-value {
  width: min-content !important;
  background: var(--primaryLight) !important;
}

.custom_select_input__multi-value__label {
  color: var(--primary) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}

.custom_select_input__multi-value__remove {
  color: var(--primary) !important;
  background: transparent !important;
}

.custom_select_input__value-container--is-multi {
  padding-top: 2px !important;
  padding-inline-start: 2px !important;
}

// --------------------------------------------------------------------map-controls---------------------------------------------------------------

// .map-controls {
//   position: absolute;
//   width: 90%;
//   top: 6%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 11;
// }

.custom-card {
  position: absolute;
  background-color: var(--bg-secondary);
  min-width: 400px;
  box-shadow: var(--box-shadow);
  top: 50%;
  z-index: 9999;
}
.map-btn {
  min-width: 195px !important;
}

.side-drawer {
  position: absolute;
  top: 9%;
  right: -2000px;
  width: 615px;
  height: 100%;
  background: white;
  box-shadow: var(--box-shadow);
  transition: right 0.3s;
  z-index: 11;
}
.map-shadow {
  box-shadow: var(--box-shadow);
}
.map-status-box {
  background-color: var(--dark);
  height: 15vh;
}
.map-stats {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 540px) {
  .side-drawer {
    width: 100% !important;
    height: max-content !important;
    z-index: 11;
  }
}

.side-drawer.open {
  right: 0;
}

// --------------------------------------------------------------------map-controls---------------------------------------------------------------

// ---------------------------------------------------Chat-------------------------------------------------------------------------------------------
.chatFloatingIcon {
  position: sticky;
  right: 20px;
  bottom: 100px;
  float: right;
}

// ---------------------------------------------------Chat-------------------------------------------------------------------------------------------
