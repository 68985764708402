.sidebar-container {
  min-width: 300px;
  flex: 40%;
  border-right: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
}

// @media screen and (min-width: 1000px) and (max-width: 1300px) {
@media screen and (min-width: 300px) and (max-width: 1300px) {
  .sidebar-container {
    flex: 35%;
  }
  .sidebar-container ~ div {
    flex: 65%;
  }
}

@media screen and (min-width: 1301px) {
  .sidebar-container {
    flex: 25%;
  }
  .sidebar-container ~ div {
    flex: 75%;
  }
}

.Header {
  background: var(--color-white);
  border-bottom: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  min-height: 60px;
}

.ImageWrapperlogo {
  width: 40px;
  height: 40px;
}

.Avatar {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.contact-container {
  flex: 1;
  overflow-y: scroll;
  background: var(--color-white);
  border-top: 1px solid var(--border);
}

.call-history-wrapper {
  width: 35px;
  height: 35px;
  font-size: 24px;
  color: var(--secondary);
  cursor: pointer;
}
.call-history-icon {
  position: absolute;
  top: -10px;
  right: 1px;
}

.chat-list-sections {
  background-color: var(--msg-sent);
  color: var(--grey);
  min-width: 50px;
  border-radius: 15px;
  font-weight: 500;
  padding: 2px 10px;
  border: 1px solid var(--border);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-list-sections:hover {
  background-color: var(--primary);
  color: var(--color-white);
}

.chat-list-sections.active {
  background-color: var(--primary);
  color: var(--color-white);
}
