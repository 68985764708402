.call-popup-dark .modal-dialog .modal-content {
  background: rgba(0, 0, 0);
  color: var(--color-white);
}
.call-popup-dark .modal-dialog .modal-content {
  background: rgba(0, 0, 0);
  color: var(--color-white);
}

.theme-btn {
  background: var(--color-white);
  color: var(--dark);
  width: 50px;
  height: 50px;
  font-size: 20px;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: transform 0.5s;
  cursor: pointer;
}
.call-users {
  transform: rotate(45deg);
}
