.pill_view {
  text-align: center;
  border: 1px solid var(--textLight);
  color: var(--textLight);
  width: 100px;
  font-family: var(--questrial);
  padding: 7px 3px;
}

.success-pill {
  border: 1px solid var(--success);
  background-color: var(--success);
  color: var(--titleWhite);
}

.danger-pill {
  border: 1px solid var(--danger);
  background-color: var(--danger);
  color: var(--titleWhite);
}

.warning-pill {
  border: 1px solid var(--warning);
  background-color: var(--warning);
  color: var(--titleWhite);
}
.pending-pill {
  border: 1px solid var(--warning);
  background-color: var(--warning);
  color: var(--titleWhite);
}
.expired-pill {
  border: 1px solid var(--dark);
  background-color: var(--dark);
  color: var(--titleWhite);
}
.role-pill {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--titleWhite);
}
.default-pill {
  border: 1px solid var(--textLight);
  color: var(--textLight);
}
.alaram-pill {
  border: 1px solid var(--info);
  background-color: var(--info);
  color: var(--titleWhite);
}
.Fault-pill {
  border: 1px solid var(--textLight);
  background-color: var(--textLight);
  color: var(--titleWhite);
}
.medium-pill {
  border: 1px solid var(--medium);
  background-color: var(--medium);
  color: var(--titleWhite);
}
.low-pill {
  border: 1px solid var(--low);
  background-color: var(--low);
  color: var(--titleWhite);
}
