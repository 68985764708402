.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px;
  min-height: 60px;
  background: var(--color-white);
  border-bottom: 1px solid var(--border);
}

.avatar-wrapper-section-header {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}

.avatar-wrapper-section-header img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.avatar-wrapper-section-header .sb-avatar__text div span {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.ProfileWrapper {
  flex: 1;
  cursor: pointer;
}

.Name {
  color: var(--dark);
  font-size: 1rem;
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.subtitle-header {
  color: var(--secondary);
  font-size: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
