.btn {
  width: max-content !important;
  padding: 9px 16px !important;
  border-radius: 5px !important;
  text-align: center !important;
  height: 40px;
}
.btn:hover {
  color: var(--titleWhite);
}
