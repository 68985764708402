$primary: #6b5ca4;
$secondary: #858780;
$success: #25d366;
$danger: #f93919;
$dark: #000000;
$white: #fefefe;
$info: #0da9e5;
$warning: #f7936f;
// $light: #f8faff;
$modal-backdrop-bg: rgba(52, 64, 84, 0.7);
$border-radius-sm: 2px;
$border-radius-lg: 10px;
$box-shadow-sm: 0 0px 4px rgba(0, 0, 0, 0.1);
$border-color: #dadada;
$h3-font-size: 1.5rem;
$h4-font-size: 20px;

// $h1-font-size: 50px;
// $h2-font-size: 35px;
// $h5-font-size: 18px;
// $h6-font-size: 16px;
@import "~bootstrap/scss/bootstrap";
// @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  // font-family: "Manrope", sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  // direction: rtl;
}

:root {
  --layout-bg: #f0f4fa;
  --layout-bg-unselect: #f8faff;
  --primary: #6b5ca4;
  --border: #dadada;
  --msg-sent: #ededed;
  --secondary: #bfbfbf;
  --color-white: #fefefe;
  --success: #4ac17f;
  --danger: #f93919;
  --success-check: #28a745;
  --dark: #000000;
  --info: #0da9e5;
  --warning: #f7936f;
  --grey: #00000099;

  --box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
  --modal-backdrop-bg: rgba(52, 64, 84, 0.7);
  --modal-backdrop-bg-2: rgba(52, 64, 84, 0.4);

  --fs-14: 14px;
  --fs-13: 13px;
  --fs-12: 12px;
  --fs-11: 11px;
  --min-width: 235px;
}

::-webkit-scrollbar {
  width: 1px;
  height: 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(204, 25, 25, 0);
}

::-webkit-scrollbar-thumb {
  background: var(--border);
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(248, 241, 241, 0);
}

.cursor-pointer {
  cursor: pointer;
}
.min-width {
  min-width: var(--min-width);
}
.fs-14 {
  font-size: var(--fs-14);
}
.fs-13 {
  font-size: var(--fs-13);
}
.fs-12 {
  font-size: var(--fs-12);
}
.fs-11 {
  font-size: var(--fs-11);
}
.m-inline-start {
  margin-inline-start: 5px;
}
.m-inline-end {
  margin-inline-end: 5px;
}

// ----------------------------------------------------chat-layout------------------------------------

.chat-layout {
  width: 100%;
  min-height: 100vh;
  // background: var(--layout-bg);
  background: linear-gradient(180deg, var(--primary) 20%, var(--layout-bg) 20%);
  position: relative;
}

.chat-layout::before {
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  z-index: 1;
}

@media screen and (min-width: 1150px) {
  .chat-layout {
    padding: 20px;
  }
}

.layout-content {
  width: 100%;
  height: 100vh;
  // height: calc(100vh - 40px);
  max-width: 1750px;
  margin: 0 auto;
  box-shadow: var(--box-shadow);
  position: relative;
  z-index: 100;
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 1150px) {
  .layout-content {
    height: calc(100vh - 40px);
  }
}

@media screen and (max-width: 912px) {
  .layout-content {
    height: 100vh;
  }
}
.sb-avatar__text {
  background-color: var(--primary) !important;
}

aside.EmojiPickerReact.epr-main {
  // border-color: var(--epr-picker-border-color);
  // border-radius: var(--epr-picker-border-radius);
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 350px !important;
  width: 100% !important;
  border-radius: 0px;
}

textarea {
  resize: both;
  resize: horizontal;
  resize: vertical;
  resize: none;
}
