.chat-body {
  min-width: 300px;
  flex: 40%;
  border-right: 1px solid var(--border);
  z-index: 1;
}

.footer-container {
  background: var(--color-white);
  border-top: 1px solid var(--border);
  position: absolute;
  width: 100%;
  bottom: 0px;
  // min-height: 80px;
}
.header-container {
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 1;
}
@media screen and (max-width: 912px) {
  .footer-container {
    position: fixed;
  }
  .header-container {
    position: fixed;
  }
}

.ScrollButton {
  position: absolute;
  right: 15px;
  bottom: 100px;
  width: 42px;
  height: 42px;
  z-index: -1;
  border-radius: 50%;
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  z-index: 10;
  cursor: pointer;
}
