.footer-wrapper {
  padding: 10px;
  // min-height: 60px;
  position: relative;
  display: flex;
  align-items: center;
}

.attach-container {
  position: absolute;
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  bottom: 50px;
  min-width: 200px;
  z-index: 1;
}

.button-attach {
  transform: scale(0);
  opacity: 0;
  transition: all 0.5s ease;
}
.attach-title {
  font-size: var(--fs-14);
  font-weight: 500;
}

.button-attach.show-icon {
  transform: scale(1);
  opacity: 1;
}

.button-attach:nth-of-type(1) {
  transition-delay: 0.25s;
}

.button-attach:nth-of-type(2) {
  transition-delay: 0.2s;
}

.button-attach:nth-of-type(3) {
  transition-delay: 0.15s;
}

.button-attach:nth-of-type(4) {
  transition-delay: 0.1s;
}

.button-attach:nth-of-type(5) {
  transition-delay: 0.05s;
}

.footer-input {
  background: var(--color-white);
  color: var(--dark);
  border: none;
  padding: 15px 10px;
  border-radius: 5px;
  flex: 1;
  height: 100%;
  margin-left: 7px;
  box-shadow: var(--box-shadow);

  &::placeholder {
    color: var(--dark);
    font-size: 0.9rem;
  }

  &:focus {
    outline: none;
  }
}
.btn-snd {
  height: 48px;
  width: 52px;
  background-color: var(--primary);
  border-radius: 12px;
}
.emoji-wrapper-footer {
  position: absolute;
  bottom: 70px;
  left: 0px;
  z-index: 1;
  width: 100%;
}

.reply-name {
  color: var(--primary);
  font-weight: 600;
  font-size: 0.9rem;
}
.reply-subtext {
  font-size: 0.9rem;
}
.reply-msg {
  position: absolute;
  bottom: 63px;
  left: 0px;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  transition: all 0.2s ease;
}
.reply-box {
  background: var(--layout-bg-unselect);
  border-left: 5px solid var(--primary);
  border-radius: 7px;
}

.forward-msg {
  position: absolute;
  bottom: -5px;
  left: 0px;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  z-index: 111;
  transition: all 0.2s ease;
}
input.file-input {
  position: absolute;
  opacity: 0;
  width: 400px;
  left: -200px;
  top: 0;
  cursor: pointer !important;
}

.file-item {
  transition: background-color 0.3s ease;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 7px;
  cursor: pointer !important;
}

.file-item:hover {
  background-color: var(--msg-sent);
}
.file-item .attach-title {
  transition: color 0.3s ease;
}

#inputID::placeholder {
  color: #a8a7a7;
  opacity: 1;
}
.textarea-footer-input {
  width: 100%;
  border: none;
  background: var(--color-white);
  color: #00000099;
  border: none;
  padding: 10px 0px 0px 10px;
  min-height: 45px;
  border-radius: 5px;
  box-shadow: var(--box-shadow);

  &::placeholder {
    color: #00000099;
  }

  &:focus {
    outline: none;
  }
}

.msg-reply-image {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid var(--border);
}
.msg-reply-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.dummy-width-audio {
  width: 45.35px;
  opacity: 1;
  transition: opacity 0.6s ease;
}

.dummy-width-audio.hidden {
  opacity: 0;
  visibility: hidden;
}

.send-btn {
  opacity: 1;
  transition: opacity 0.6s ease;
}

.send-btn.hidden {
  opacity: 0;
  visibility: hidden;
}
