:root {
  --color: var(--primary);
  --duration: 1s;
}

.call-connections {
  width: 100%;
  height: 100%;
  background-color: var(--modal-backdrop-bg-2);
  border-radius: 7px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
}
.text-conntection {
  color: var(--primary);
}
.call-connection-wrapper {
  background-color: var(--color-white);
  border-radius: 7px;
}
.call-controls {
  color: var(--primary);
  font-size: 26px;
}

// .running {
//   zoom: 1;
//   margin-top: -15px;
// }

// .outer {
//   animation: outer var(--duration) linear infinite;
// }

// .running-body {
//   background: var(--color);
//   height: 15px;
//   width: 8px;
//   border-radius: 4px;
//   transform-origin: 4px 11px;
//   position: relative;
//   transform: rotate(32deg);
//   animation: running-body var(--duration) linear infinite;
// }

// .running-body:before {
//   content: "";
//   width: 8px;
//   height: 8px;
//   border-radius: 4px;
//   bottom: 16px;
//   left: 0;
//   position: absolute;
//   background: var(--color);
// }

// .arm,
// .arm:before,
// .leg,
// .leg:before {
//   content: "";
//   width: var(--w, 11px);
//   height: 4px;
//   top: var(--t, 0);
//   left: var(--l, 2px);
//   border-radius: 2px;
//   transform-origin: 2px 2px;
//   position: absolute;
//   background: var(--c, var(--color));
//   transform: rotate(var(--r, 0deg));
//   animation: var(--name, arm-leg) var(--duration) linear infinite;
// }

// .arm:before {
//   --l: 7px;
//   --name: arm-b;
// }

// .arm.front {
//   --r: 24deg;
//   --r-to: 164deg;
// }

// .arm.front:before {
//   --r: -48deg;
//   --r-to: -36deg;
// }

// .arm.behind {
//   --r: 164deg;
//   --r-to: 24deg;
// }

// .arm.behind:before {
//   --r: -36deg;
//   --r-to: -48deg;
// }

// .leg {
//   --w: 12px;
//   --t: 11px;
// }

// .leg:before {
//   --t: 0;
//   --l: 8px;
// }

// .leg.front {
//   --r: 10deg;
//   --r-to: 108deg;
// }

// .leg.front:before {
//   --r: 18deg;
//   --r-to: 76deg;
// }

// .leg.behind {
//   --r: 108deg;
//   --r-to: 10deg;
//   --c: none;
// }

// .leg.behind:before {
//   --c: var(--color);
//   --r: 76deg;
//   --r-to: 18deg;
// }

// .leg.behind:after {
//   content: "";
//   top: 0;
//   right: 0;
//   height: 4px;
//   width: 6px;
//   clip-path: polygon(2px 0, 6px 0, 6px 4px, 0 4px);
//   border-radius: 0 2px 2px 0;
//   position: absolute;
//   background: var(--color);
// }

// @keyframes outer {
//   50% {
//     transform: translateY(0);
//   }
//   25%,
//   75% {
//     transform: translateY(4px);
//   }
// }

// @keyframes running-body {
//   50% {
//     transform: rotate(16deg);
//   }
//   25%,
//   75% {
//     transform: rotate(24deg);
//   }
// }

// @keyframes arm-leg {
//   50% {
//     transform: rotate(var(--r-to));
//   }
// }

// @keyframes arm-b {
//   30%,
//   70% {
//     transform: rotate(var(--r-to));
//   }
// }
