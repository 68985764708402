#dropdown-basicActions {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
  color: var(--color) !important;
  height: 100% !important;
}

.Actions-drop .dropdown-toggle::after {
  border: 0 !important;
}

.dropdown-menu.show {
  border: none;
  box-shadow: var(--box-shadow);
  transition: all 0.2s ease-in;
  transform: scale(1);
  left: -155px !important;
  min-width: 180px;
  padding: 10px;
}
.dropdown-item {
  color: var(--dark) !important;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  margin: 0px 0px 5px 0px;
  padding: 10px 15px;
  width: 160px;
}
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: var(--msg-sent);
  border-radius: 5px;
}
