
.lk-room-container{
  width: 100% ;
  height: 100%;
}

.call-interface {
  width: 100% ;
}

.carousel-container {
  width: 100% ;
  border-radius: 10px;
  background: var(--layout-bg-unselect);
}


.participant-2 .lk-participant-media-video{
  max-width: 530px;
  width: 100% ;
  height: 300px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  object-fit: cover;
  background-position: top;
  background-size: cover;

}
   /* width: 400px;
    height: 200px; */

@media screen and (max-width: 1024px) {

.call-interface {
  height: 60vh;
}
.carousel-container {
  height: 500px;
}
}

.lk-participant-placeholder{
  display: none;
}
.lk-participant-metadata-item.lk-connection-quality{
  display: none;

}
/* .lk-participant-metadata{
  display: none;

} */
 .lk-participant-metadata{
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
 }
 .lk-participant-metadata-item{
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;

 }

 .participant-name-video{
  padding: 5px;
  position: absolute;
  bottom: 10px;
  right: 5px;
  border-radius: 10px;
  font-size: var(--fs-13);
  text-transform: capitalize;
  background: rgba(0, 0, 0, 0.5);
  color: var(--color-white);

 }
 .participant-name-audio{
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: var(--fs-13);
  text-transform: capitalize;
  /* background: rgba(0, 0, 0, 0.3); */
  background-color: var(--modal-backdrop-bg-2);
  color: var(--color-white);
  width: 100%;
  height: 100%;
 }

 .lk-track-muted-indicator-microphone{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 10px;
  font-size: var(--fs-13);
  text-transform: capitalize;
  background: rgba(0, 0, 0, 0.5);
  color: var(--color-white);
  z-index: 1;
 }

.control-btn{
  background: var(--color-white);
  color: var(--dark);
  width: 50px;
  height: 50px;
  font-size: 24px;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;

}

.leave-call,.leave-call-disabled {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}
.leave-call {  background-color: var(--danger);
}
.leave-call::after {
  content: "";
  background-color: var(--danger);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 50%;
  animation: 3s pulse infinite;
}
.leave-call-disabled{
  background: var(--msg-sent);

}
.leave-call-disabled::after {
  content: "";
  background: var(--msg-sent);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  border-radius: 50%;
  animation: 2s pulse infinite;
}

.lk-participant-media-video{
  max-width: 530px;
  width: 100% ;
  height: 300px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}
.participant-media-video-off{
  background: var(--color-white);
  position: absolute;
  left: 0px;
  top: 0px;
  max-width: 530px;
  width: 100% ;
  /* height: 300px; */
  height: 100%;

}



.img-blur {
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1'%3E%3CfeGaussianBlur stdDeviation='4' result='b'/%3E%3CfeMorphology operator='dilate' radius='0'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E%3C/svg%3E#a");
}
.img-blur img {
  width: 100%;
  height: 100%;
}


.muted-call{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  z-index: 99999;
  border: 1px solid var(--color-white);
  border-radius: 50%;
  box-shadow: var(--box-shadow);

}
.muted-call img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: var(--box-shadow);
}
.muted-call .sb-avatar__text div span {
  font-size: 4rem !important;
}

@media screen and (max-width: 1200px) {
  .lk-participant-media-video{
    max-width: 355px;
    width: 100% ;
    height: 200px;
    box-shadow: var(--box-shadow);
  }
  .participant-2 .lk-participant-media-video{
  max-width: 355px;
   width: 100% ;
   height: 200px;
   box-shadow: var(--box-shadow);
   object-fit: cover;
   background-position: top;
   background-size: cover;

}
  .participant-media-video-off{
    background: var(--color-white);
    position: absolute;
    left: 0px;
    top: 0px;
    max-width: 355px;
    width: 100% ;
    /* height: 200px; */
    height: 100%;

  }
  .muted-call{
    width: 100px;
    height: 100px;
  }
}
