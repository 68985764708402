.contact {
  height: 72px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--msg-sent);
  cursor: pointer;

  &:hover {
    background-color: var(--layout-bg-unselect);
  }
}

.contact.contact-active {
  background-color: var(--layout-bg-unselect);
}

.avatar-wrapper-section {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.avatar-wrapper-section img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.avatar-wrapper-section .sb-avatar__text div span {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.Time {
  font-size: 0.7rem;
  font-weight: 500;
  color: var(--secondary);
}

.MessageWrapper {
  color: var(--secondary);
  font-size: 0.85rem;
}

.read {
  color: var(--info);
}

.UnreadContact {
  display: inline-block;
  color: var(--color-white) !important;
  background-color: var(--primary);
  border-radius: 18px;
  min-width: 18px;
  height: 18px;
  padding: 0 3px;
  line-height: 18px;
  vertical-align: middle;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
}

// -----------------------------------------------------------bage online offline----------------------
.styled-badge-wrapper {
  position: absolute;
  bottom: 0px;
  right: 2px;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}

.styled-badge {
  background-color: var(--success);
  color: var(--success);
  border: 1px solid var(--color-white);
  box-shadow: var(--box-shadow);
  position: relative;
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.styled-badge .MuiBadge-badge::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ripple 1.2s infinite ease-in-out;
  border: 1px solid currentColor;
  content: "";
}
