.PhoneInputInput {
  border: 0 !important;
  display: flex !important;
  width: 100%;
  outline: none !important;
  background-color: var(--bs-body-bg) !important
}

.PhoneInputInput:disabled {
  background: #f2f2f2 !important;
}

.PhoneInput {
  display: flex !important;
}

.PhoneInput:disabled {
  background: #f2f2f2 !important;
}
