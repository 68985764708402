.incoming {
  background-color: var(--color-white);
  width: 300px;
  color: var(--dark);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  z-index: 999;
  position: relative;
  border-radius: 10px;
}

.incoming-bg {
  height: 160px;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  //   filter: blur(3px);
  overflow: hidden;
  filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1'%3E%3CfeGaussianBlur stdDeviation='4' result='b'/%3E%3CfeMorphology operator='dilate' radius='0'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E%3C/svg%3E#a");
}
.incoming-bg img {
  width: 100%;
  height: 100%;
}
.incoming-avatar-bg {
  background-color: var(--msg-sent);
}

.incoming-wrapper {
  position: absolute;
  right: 60px;
  bottom: 120px;
  z-index: 9999;
  text-align: center;
}

.decline-call,
.accept-call {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  cursor: pointer;
}
.decline-call:hover {
  transform: translateY(-10px);
}
.accept-call:hover {
  transform: translateY(-10px);
}

.accept-call {
  background-color: var(--success);
  transition: transform 0.5s;
  transform: translateY(-10px);
}
.decline-call {
  background-color: var(--danger);
  transition: transform 0.5s;
}
.incoming-avatar {
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 120px;
  height: 120px;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 75%);
  z-index: 9999;
}
.incoming-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.incoming-avatar::after {
  content: "";
  background-color: var(--color-white);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  border-radius: 50%;
  animation: 2s pulse infinite;
}

@keyframes pulse {
  from {
    transform: none;
    opacity: 0.7;
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.incoming-text {
  margin-top: 70px;
  color: var(--secondary);
}
.contact-name {
  font-weight: 300;
  text-transform: capitalize;
}

.incoming-avatar .sb-avatar__text div span {
  font-size: 35px !important;
}

.accept,
.ignore,
.accept-disabled {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.accept {
  background: var(--success);
}
.accept-disabled {
  background: var(--msg-sent);
}
.accept-disabled::after {
  content: "";
  background: var(--msg-sent);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  border-radius: 50%;
  animation: 2s pulse infinite;
}

.accept::after {
  content: "";
  background-color: var(--success);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  border-radius: 50%;
  animation: 2s pulse infinite;
}

.ignore {
  background: var(--danger);
}

.ignore::after {
  content: "";
  background-color: var(--danger);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  border-radius: 50%;
  animation: 2s pulse infinite;
}

// --------------------------------------------------------------------------------

.incoming-wrapper-responsive {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: var(--dark);
  border-bottom: 1px solid var(--border);
  z-index: 1111;
  color: var(--color-white);
}

.incoming-avatar-responsive {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  position: relative;
}
.incoming-avatar-responsive img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
@media screen and (max-width: 912px) {
  .incoming-avatar-responsive {
    width: 40px;
    height: 40px;
  }
  .contact-name {
    font-size: 18px;
  }
  .incoming-alert {
    font-size: var(--fs-13);
  }
}

.incoming-avatar-responsive::after {
  content: "";
  background-color: var(--color-white);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  border-radius: 50%;
  animation: 2s pulse infinite;
}

.accept-responsive,
.ignore-responsive,
.accept-disabled-responsive {
  transition: opacity 0.2s;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.5s;
}

// .accept-responsive:hover {
//   transform: translateY(-5px);
// }

// .ignore-responsive:hover {
//   transform: translateY(-5px);
// }

.accept-responsive {
  background: var(--success);
}

.ignore-responsive {
  background: var(--danger);
}

.accept-disabled-responsive {
  background: var(--msg-sent);
}
.accept-disabled-responsive::after {
  content: "";
  background: var(--msg-sent);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  border-radius: 50%;
  animation: 2s pulse infinite;
}
