.sidebar-profile-container {
  width: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.6s ease;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
}

/* Apply this when the sidebar is open */
.sidebar-profile-container.open {
  flex: 10%;
  display: block;
  transition: all 0.6s ease;
}

@media screen and (max-width: 1000px) {
  .sidebar-profile-container {
    border-left: 1px solid var(--border);
    transition: transform 0.1s ease;
    // transform: translateX(120vw);
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}

.side-profile-header {
  position: absolute;
  top: 0px;
  left: 0px;
  background: var(--color-white);
  border-bottom: 1px solid var(--border);
  min-height: 60px;
  width: 100%;
}

.profile-wrapper {
  background: var(--color-white);
  height: calc(100vh - 40px);
}

.PersonalInfo {
  background: var(--color-white);
}

.AvatarWrapper-profile {
  width: 200px;
  height: 200px;
  margin-top: 60px;
}

@media screen and (max-width: 1024px) {
  .profile-wrapper {
    height: 100vh !important;
  }
  .AvatarWrapper-profile {
    width: 70px;
    height: 70px;
    margin-top: 60px;
  }
  .AvatarWrapper-profile .sb-avatar__text div span {
    font-size: 2rem !important;
  }
}

.AvatarWrapper-profile img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.media-section {
  background: var(--color-white);
}
.about-section {
  background: var(--color-white);
}

.profile-heading {
  color: var(--primary);
  font-size: 0.85rem;
}

.AboutItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: 500;
  color: var(--grey);

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--border);
  }
}
.action-section {
  background: var(--color-white);
}
.profile-input input {
  background: var(--color-white);
  color: var(--dark);
  border: none;
  flex: 1;
  height: 100%;
  padding: 5px;

  &::placeholder {
    color: var(--dark);
    font-size: 0.9rem;
  }

  &:focus {
    outline: none;
  }
}

.AvatarWrapper-profile .sb-avatar__text div span {
  font-size: 4rem;
}
.group-member {
  width: 40px;
  height: 40px;
}
.group-member img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
#inputID::placeholder {
  color: #a8a7a7;
  opacity: 1;
}
.group-member-list {
  height: 48vh;
  overflow-y: scroll;
  padding-bottom: 65px;
}

.exit-group-btn {
  min-height: 72px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: var(--msg-sent);
  // color: var(--dark);
  color: var(--danger);
  width: 100%;
  z-index: 1;
}
.emoji-wrapper-profile {
  position: absolute;
  top: 35px;
  right: 0px;
  width: 100%;
}
