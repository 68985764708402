.chat-loader {
  width: 100%;
  height: 100%;
  background-color: var(--modal-backdrop-bg-2);
  border-radius: 7px;
  position: absolute;
  z-index: 9999;
}
.chat-gif {
  width: 200px;
  //   height: 200px;
  background-color: var(--color-white);
  border-radius: 10px;
}

.chat-loading-text {
  left: 50%;
  top: 17%;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 4.4px;
  text-transform: capitalize;
  position: absolute;
  overflow: hidden;
  transform: translate(-40%, -60%);
}

.chat-loading-text:before {
  color: var(--secondary);
  content: attr(data-loading-text);
}
.chat-loading-text:after {
  top: 0;
  left: 0;
  width: 0;
  opacity: 1;
  color: #524f4f99;
  overflow: hidden;
  position: absolute;
  content: attr(data-loading-text);
  -webkit-animation: chat-loading-text 5s infinite;
  animation: chat-loading-text 5s infinite;
}
@-webkit-keyframes chat-loading-text {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes chat-loading-text {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
