.preview-container {
  background-color: var(--color-white);
  transition: transform 0.1s ease;
  position: absolute;
  //   top: 60px;
  bottom: 0px;
  right: 0;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 999999;
}
.preview-content {
  height: calc(100vh - 145px);
}

@media screen and (min-width: 1150px) {
  .preview-container {
    height: calc(100vh - 100px);
  }
  .preview-content {
    height: calc(100vh - 180px) !important;
  }
}

.preview-footer {
  height: 80px;
  position: absolute;
  bottom: -80px;
  left: 0px;
  background-color: var(--layout-bg-unselect);
}

.preview-image {
  width: 400px;
  height: 500px;
  overflow: hidden;
  border-radius: 10px;
}
.preview-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  background-repeat: no-repeat;
}
.preview-video {
  width: 400px;
  border-radius: 10px;
}
.preview-img-cut-wrapper {
  width: 350px;
  overflow-x: scroll;
}
.preview-img-cut {
  width: 52px;
  min-width: 52px;
  max-width: 52px;
  height: 52px;
  cursor: pointer;
  position: relative;
}
.preview-img-cut img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  pointer-events: none;
}
.preview-img-cut:hover {
  outline: 2px solid var(--primary);
  border-radius: 10px;
}
.preview-img-close {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: end;
  background-color: rgba(52, 64, 84, 0.4);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.add-file {
  width: 52px;
  height: 52px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 1px solid var(--border);
  border-radius: 10px;
}
.send-btn {
  transform: rotate(40deg);
}
#inputID::placeholder {
  color: #a8a7a7;
  opacity: 1;
}
