.container-unselect {
  background: var(--layout-bg-unselect);
  padding: 20px;
  height: 100%;
  flex: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 6px solid var(--primary);
}

.image-wrapper {
  width: 550px;
  margin-bottom: 20px;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.title-unselect {
  color: var(--dark);
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.text-unselect {
  color: var(--grey);
  font-size: 0.9rem;
  font-weight: 500;
  max-width: 500px;
  line-height: 24px;

  &:first-of-type {
    padding-bottom: 30px;
  }

  &:last-of-type {
    padding-top: 10px;
  }
}

.Link {
  margin-left: 5px;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}
