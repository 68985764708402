.modal-content {
  border: none !important;
}
.userlist-img-wrapper {
  width: 50px;
  height: 50px;
}
.userlist-img-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.role-list {
  font-weight: 500;
  font-size: var(--fs-13);
  color: var(--secondary);
  letter-spacing: 1px;
}
.people-list {
  height: 65vh;
  overflow-y: scroll;
}
.view-more {
  position: sticky;
  bottom: 0px;
  left: 0px;
  height: 60px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  color: var(--primary);
  width: 100%;
  font-weight: 600;
  cursor: pointer;
}
.forward-send {
  position: sticky;
  bottom: 0px;
  left: 0px;
  height: 60px;
  background-color: var(--layout-bg-unselect);
  border-top: 1px solid var(--border);
  width: 100%;
  font-weight: 600;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.group-btn-wrapper {
  background-color: var(--layout-bg-unselect);
  border-radius: 10px;
  cursor: pointer;
  box-shadow: var(--box-shadow);
}
.new-conversation-list:hover {
  background-color: var(--layout-bg-unselect);
  box-shadow: var(--box-shadow);
}
.new-conversation-list-active {
  background-color: var(--primaryLight);
  box-shadow: var(--box-shadow);
}

.group-btn {
  height: 52px;
  width: 52px;
  background-color: var(--primary);
  color: var(--color-white);
  font-size: 24px;
  border-radius: 50%;
}
.textarea-input {
  width: 100%;
  border: none;
  background: #fefefe;
  color: #00000099;
  border: none;
  padding: 10px 0px 0px 10px;
  min-height: 45px;
  border-radius: 5px;
  box-shadow: var(--box-shadow);

  &::placeholder {
    color: #00000099;
  }

  &:focus {
    outline: none;
  }
}
.add-people {
  position: sticky;
  bottom: 0px;
  left: 0px;
  height: 100px;
  background-color: var(--layout-bg-unselect);
  box-shadow: var(--box-shadow);
  width: 100%;
  font-weight: 600;
  cursor: pointer;
}

.group-add-people-btn {
  height: 52px;
  width: 52px;
  background-color: var(--primary);
  color: var(--color-white);
  font-size: 24px;
  border-radius: 50%;
}

.add-member-select {
  min-height: 5vh;
  max-height: 10vh;
  overflow-y: scroll;
}
.selected-member {
  padding: 5px 10px;
  background: var(--msg-sent);
  font-size: var(--fs-13);
  border-radius: 15px;
  border: 1px solid var(--border);
  cursor: pointer;
}
