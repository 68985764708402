.delete-popup-wrapper {
  background-color: rgba(30, 42, 49, 0.5);
  height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 9999;
}
.delete-popup {
  background-color: var(--color-white);
  margin: 10px;
  padding: 20px;
  width: 550px;
}
@media screen and (max-width: 540px) {
  .delete-popup {
    width: 100%;
    margin: 10px;
  }
}
