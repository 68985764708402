.call-history-list {
  width: 45px;
  height: 45px;
}
.call-history-list img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
