.calender_icon {
  position: absolute;
  right: 13px;
  top: 10px;
  cursor: pointer;
}
.rmdp-wrapper {
  background-color: #ffffff !important;
  box-shadow: var(--box-shadow) !important;
}
.rmdp-panel-body li {
  background-color: var(--primary) !important;
}
