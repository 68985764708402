.new-group-icon {
  height: 200px;
  width: 200px;
  background-color: var(--msg-sent);
  color: var(--color-white);
  font-size: 120px;
  border-radius: 50%;
  overflow: hidden;
}
.add-group-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(30, 42, 49, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.create-new-grp-btn {
  background-color: var(--primary);
  color: var(--color-white);
  height: 48px;
  border-radius: 7px;
  font-weight: 600;
  cursor: pointer;
}

.file-input-group {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 150px;
  opacity: 0;
  cursor: pointer !important;
}

#inputID::placeholder {
  color: #a8a7a7;
  opacity: 1;
}
.emoji-wrapper-group {
  position: absolute;
  top: 50px;
  right: 0px;
  width: 100%;
}
