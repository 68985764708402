.timer-count {
  font-size: 50px;
}
.audio-button {
  padding: 0.8rem 2rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  color: var(--color-white);
  transition: all 300ms ease-in-out;
}

.btn-audio-success {
  background-color: var(--success);
}
.btn-audio-danger {
  background-color: var(--danger);
  transform: translateY(0);
}
.btn-audio-warning {
  background-color: var(--warning);
  transform: translateY(0);
}
.btn-audio-primary {
  background-color: var(--primary);
  transform: translateY(0);
}
