.msg-container {
  flex: 1;
  position: relative;
  background: var(--layout-bg-unselect);
  overflow-y: scroll;
  padding: 20px 5% 2pc;
  height: 770px;
  margin-top: 60px;
  margin-bottom: 80px;
}

.DateWrapper {
  text-align: center;
  margin: 10px 0 14px;
  position: relative;
}
.Date {
  background-color: var(--primary);
  display: inline-block;
  color: var(--color-white);
  font-size: 0.75rem;
  letter-spacing: 1px;
  padding: 4px 10px;
  border-radius: 5px;
}

.EncryptionMessage {
  background: #fdf4c5;
  color: #000;
  font-size: 0.77rem;
  text-align: center;
  padding: 5px 10px;
  position: relative;
  margin-bottom: 8px;
  border-radius: 5px;
  line-height: 20px;
  z-index: 9;
}
.group-name-tag {
  color: var(--primary);
}

.MessageGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  position: relative;
  height: 100%;
}

.MessageGroup .chat__msg--sent {
  background: var(--primary);
  color: var(--color-white);
  align-self: flex-end;
}

.MessageGroup .chat__msg--received {
  background: var(--msg-sent);
  align-self: flex-start;
}

.ChatMessage {
  padding: 6px 8px 8px 9px;
  font-size: 0.85rem;
  width: fit-content;
  max-width: 95%;
  line-height: 20px;
  border-radius: 5px;
  position: relative;
  white-space: pre-wrap;
  display: flex;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  @media screen and (min-width: 1301px) {
    max-width: 65%;
  }
  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    max-width: 75%;
  }
  @media screen and (min-width: 900px) and (max-width: 1000px) {
    max-width: 85%;
  }
}

.ChatMessage img {
  width: 200px;
}

.msg-option-list {
  position: absolute;
  right: -29px;
  bottom: 0px;
}
.msg-image {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}
.msg-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.msg-image-reply {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}
.msg-image-reply img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.msg-text-for-attachment {
  width: 200px;
}
.msg-video {
  width: 300px;
  border-radius: 10px;
}
@media screen and (max-width: 540px) {
  .msg-video {
    width: 100%;
  }
}

.reply-show-extra-padding {
  padding-bottom: 100px;
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.checkbox input {
  position: absolute;
  width: 0;
  left: 50px;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.checkbox .checkmark {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background: var(--color-white);
  border-radius: 3px;
  outline: 1px solid var(--border);
  transition: all 0.2s ease;
}

.checkbox:hover .checkmark {
  background: var(--color-white);
  transition: all 0.2s ease;
}

.checkbox input:checked ~ .checkmark {
  background: var(--primary);
  outline: 1px solid var(--primary);
}

.checkbox input[type="radio"] ~ .checkmark {
  border-radius: 50%;
}

.checkbox .checkmark::after {
  position: absolute;
  display: block;
  content: "";
  left: 50%;
  top: 40%;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -moz-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
  transition: all 0.2s ease;
}

.checkbox input:checked ~ .checkmark::after {
  opacity: 1;
  transition: all 0.2s ease;
}

.is-received {
  width: 100% !important;
  justify-content: flex-start !important;
}
.is-sent {
  width: 100% !important;
  justify-content: space-between;
}
.check-box-margin {
  margin-top: 20px !important;
}

.is-both-checked {
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  transition: all 0.2s ease;
}

.is-reply {
  width: 100%;
  background-color: var(--color-white);
  transition: all 0.2s ease;
  border-radius: 7px;
}
.is-reply-box {
  background: var(--layout-bg-unselect);
  border-left: 5px solid var(--primary);
  border-radius: 7px;
  box-shadow: var(--box-shadow);
}
.forwarded-text {
  font-style: italic;
}
.file-show {
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 7px;
  color: var(--grey);
}
// ------------------------------------------------------
.file-download {
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.file-download.fade-out {
  opacity: 0;
}
.call-wrapper {
  min-width: 200px;
  background: var(--layout-bg-unselect);
  color: var(--grey);
  border-radius: 5px;
}
.call-icon {
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
}
.preview-img-wrapper {
  width: 100%;
  height: 100%;
}
.preview-img-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  object-fit: contain;
}

@media screen and (max-width: 540px) {
  audio {
    width: 100%;
    min-width: 260px;
  }
}

audio::-webkit-media-controls-panel {
  background-color: var(--color-white);
  color: var(--primary) !important;
}
audio::-webkit-media-controls-play-button {
  color: var(--primary) !important;
}
.edit-message {
  background: var(--msg-sent);
}
.edit-message-bg {
  background-color: var(--primary);
  color: var(--color-white);
  font-size: var(--fs-14);
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
}

.edit-input {
  background: var(--color-white);
  color: var(--dark);
  border: none;
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  height: 100%;
  box-shadow: var(--box-shadow);

  &::placeholder {
    color: var(--dark);
    font-size: 0.9rem;
  }

  &:focus {
    outline: none;
  }
}
.edit-message-snd {
  background: var(--layout-bg-unselect);
}
.time-container {
  height: 15px;
  overflow: hidden;
}
.video-call-incoming {
  position: absolute;
  top: 2px;
  left: -1px;
  transform: rotate(-128deg);
}
.video-call-outgoing {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(50deg);
}
.dummy-width-message {
  width: 50px;
}

.emoji-wrapper-edit {
  position: absolute;
  bottom: 70px;
  left: 0px;
  z-index: 1;
  width: 100%;
}

// ------------------------------------------------------------------message-dot-loader-----------------------------------------------------

// <div className="message-dot-loader"></div>

// .message-dot-loader {
//   width: 30px;
//   aspect-ratio: 4;
//   background: radial-gradient(
//       circle closest-side,
//       var(--color-white) 80%,
//       #0000
//     )
//     0 / calc(100% / 3) 100% space;
//   clip-path: inset(0 100% 0 0);
//   animation: l1 1s steps(4) infinite;
// }
// @keyframes l1 {
//   to {
//     clip-path: inset(0 -34% 0 0);
//   }
// }
